import React from "react";
//IMAGES
import GlobxPinguino from "../../images/GlobxPinguino.png";

export default function header() {
  //Get Username
  const username = sessionStorage.getItem("username");

  const Logout = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.BASE_URL}`;
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("codeExpire");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("id_token");
  };

  return (
    <>
      <div className="row m-0">
        {/* Logo Section */}
        <div className="col-12 col-md-2 text-center text-md-start my-2 my-md-0">
          <nav className="navbar navbar-light bg-white">
            <div>
              <img
                src={GlobxPinguino}
                className="img-fluid mx-auto d-block d-md-inline"
                style={{ width: "60%" }}
                alt="logo"
              />
              <h5
                className="text-center text-md-start mt-2 mt-md-0"
                style={{ color: "#000000", fontWeight: "bold" }}
              >
                Options & Futures
              </h5>
            </div>
          </nav>
        </div>

        {/* Empty Middle Section for alignment (shown only on medium screens and larger) */}
        <div className="col-md-5 d-none d-md-block"></div>

        {/* Username Section */}
        <div className="col-8 col-md-4 text-center text-md-end my-auto">
          <h4 style={{ color: "#000000", fontWeight: "bold" }}>{username}</h4>
        </div>

        {/* Logout Button Section */}
        <div className="col-4 col-md-1 text-center my-auto">
          <button
            type="button"
            className="btn btn-secondary btn-md"
            style={{ background: "#050227" }}
            onClick={Logout}
          >
            LOGOUT
          </button>
        </div>

        {/* Horizontal Divider */}
        <div className="col-12 mt-2">
          <hr
            style={{
              height: "1px",
              backgroundColor: "#000000",
            }}
          />
        </div>
      </div>
    </>
  );
}
