import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import CorrelationStudy from "../components/correlationStudy/CorrelationStudy";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import WalletPerformanceTables from "../components/walletPerformanceTables/WalletPerformanceTables";
import BreadcrumbC from "../components/common/BreadcrumbC";
import Loading from "../components/loading/Loading";

export default function CorrelationStudyPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <BreadcrumbC />
      <CorrelationStudy />
      <WalletPerformanceTables />
      <Footer />
    </Suspense>
  );
}
