//React & General Libraries
import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

//Bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

//REACT HOOKS
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input } from "antd";

//ACTIONS
import Loading from "../loading/Loading";
import { setCorrelationProducts } from "../../actions/correlationStudyNxn";

//TIME & PRODUCTS DATA (JSON)
import products_data from "../../data/products.json";
import res_values from "../../data/res_values.json";

//IMPORT CSS
import "./CorrelationStudy.css";

//GIFS
import GIF from "../common/Gif";
import phrases_list from "../../data/phrases.json";
import recuerda_gif from "../../gifs/RECUERDA_GIFF_VIDEO.gif";
import sabias_gif from "../../gifs/SABIAS-QUE_GIFF_VIDEO.gif";

export default function CorrelationStudy() {
  //React HOOKS
  const dispatch = useDispatch();

  // GIF Phrases
  const [randomPhrase, setRandomPhrase] = useState("");
  const [showRecuerda, setShowRecuerda] = useState(true);
  const [showSabias, setShowSabias] = useState(false);

  // GIF Recuerda Timer
  useEffect(() => {
    if (showRecuerda) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.recuerda_phrases.length
      );
      setRandomPhrase(phrases_list.recuerda_phrases[randomIndex]);

      const recuerdaTimer = setTimeout(() => {
        setShowRecuerda(false);
        setTimeout(() => {
          setShowSabias(true);
        }, 120000); // 2 minutes delay after recuerda disappears (2 * 60 * 1000)
      }, 18000); // Recuerda message appears for 18 seconds

      return () => clearTimeout(recuerdaTimer);
    }
  }, [showRecuerda]);

  // GIF Sabias Timer
  useEffect(() => {
    if (showSabias) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.sabias_phrases.length
      );
      setRandomPhrase(phrases_list.sabias_phrases[randomIndex]);

      const sabiasTimer = setTimeout(() => {
        setShowSabias(false);
        setTimeout(() => {
          const showNext =
            Math.random() < 0.5 ? setShowRecuerda(true) : setShowSabias(true);
        }, 120000); // 2 minutes delay after recuerda disappears (2 * 60 * 1000)
      }, 18000); // Recuerda message appears for 18 seconds

      return () => clearTimeout(sabiasTimer);
    }
  }, [showSabias]);

  //React Store Variables
  const loading = useSelector((state) => state.loading.loadingCorrelation);
  const loadingCorrelationProducts = useSelector(
    (state) => state.loading.loadingCorrelationProducts
  );
  const correlationNXN = useSelector(
    (state) => state.correlationStudyNxn.results
  );

  //React State Variables
  const [valorX, setXValue] = useState(null);
  const [operation_time_hours, setOperationTimeHours] = useState(0);
  const [correlationValue, setCorrelationValue] = useState("");
  const [principalProducts, setPrincipalProducts] = useState([]);

  //Custom variables
  const products = products_data.products;

  //Get correlation product keys
  if (correlationNXN.length > 0) {
    var keys = Array.from(
      new Set([correlationNXN[0]].flatMap((item) => Object.keys(item)))
    );
  }

  //Get correlation Products data
  const getCorrelationProducts = (e) => {
    e.preventDefault();
    const products_label = [];
    principalProducts.forEach(function (e) {
      products_data.products.filter((obj) => {
        if (obj.label == e) {
          products_label.push(obj.value);
        }
      });
    });
    const data = {
      product_list: products_label,
      product_time_operation: [operation_time_hours],
      resolution: valorX,
    };
    dispatch(setCorrelationProducts(data));
  };

  //Get principal product
  var handlePrincipalProducts = (value) => {
    //Filter operation time from products
    const list_ope_time = [];
    value.forEach(function (e) {
      products_data.products.filter((obj) => {
        if (obj.label == e) {
          list_ope_time.push(Number(String(obj.tradeTime).split(" ")[0]));
        }
      });
    });
    setOperationTimeHours(Math.min(...list_ope_time));
    if (value.length <= 10) {
      setPrincipalProducts(value);
    }
  };

  // Corrleation NxN table colors
  const getColorForValue = (value) => {
    // If the value is between 0.5 and 1, calculate the color according to the yellow to green range
    if (value >= 0.5 && value <= 1) {
      const normalizedValue = (value - 0.5) / 0.5;
      const r = Math.round(255 * (1 - normalizedValue));
      const g = 255;
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is between 0 and 0.5, calculate the color according to the red to yellow range
    if (value >= 0 && value < 0.5) {
      const normalizedValue = value / 0.5;
      const r = 255;
      const g = Math.round(255 * normalizedValue);
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is between -0.5 and 0, calculate the color according to the yellow to red range
    if (value >= -0.5 && value < 0) {
      const normalizedValue = Math.abs(value) / 0.5;
      const r = 255;
      const g = Math.round(255 * normalizedValue);
      const b = 0;
      return rgbToHex(r, g, b);
    }
    //If the value is between -1 and -0.5, calculate the color according to the green to yellow range
    if (value >= -1 && value < -0.5) {
      const normalizedValue = (Math.abs(value) - 0.5) / 0.5;
      const r = Math.round(255 * (1 - normalizedValue));
      const g = 255;
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is out of range, return a neutral color
    return "#FFFFFF";
  };

  // Auxiliary function to convert RGB to hexadecimal
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  //Download PDF function
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Add title to the PDF
    doc.text("Tabla de Correlación de Productos", 10, 10);

    // data table
    const data = [];

    // Add the header as the first row
    const headerRow = [""]; // Empty cell for top left corner
    headerRow.push(...keys); // Add column headers
    data.push(headerRow);

    // Add the rest of the rows
    keys.forEach((rowKey) => {
      const rowData = [];
      rowData.push(rowKey); // Add row name as first element
      keys.forEach((colKey) => {
        const value =
          correlationNXN
            .find((item) => item[rowKey])
            ?.[rowKey]?.[colKey]?.toFixed(2) ?? "";
        rowData.push(value); // Add the correlation values ​​for each product pair
      });
      data.push(rowData); // Add entire row to table data
    });

    // Configure table styles
    const styles = {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
      margin: { top: 20 },
    };

    // Generate the table in the PDF
    doc.autoTable({
      head: [data[0]],
      body: data.slice(1),
      startY: 30,
      theme: "plain",
      styles: styles,
    });

    // Save the PDF file
    doc.save("tabla_correlacion_productos.pdf");
  };

  // Logic to set the value once the function has completed
  useEffect(() => {
    const valorObtenido = correlationNXN[1] || "Por mostrar";
    setCorrelationValue(valorObtenido);
  }, [correlationNXN]);

  //Return JSX
  return (
    <>
      {loading || loadingCorrelationProducts ? <Loading /> : ""}
      <section>
        {/* First Section */}
        <div className="text-center mt-5 mb-5">
          <h1 style={{ fontWeight: "bold" }}>Estudio de Correlación</h1>
        </div>

        {/* Select information */}
        <Card className="mx-3 mb-5" style={{ borderColor: "#050227" }}>
          <Container fluid>
            <div className="mt-5 mb-5 mx-3 row text-center">
              {/* Select X resolution */}
              <div className="col-12 col-md-4 mb-3">
                <div className="row">
                  <label className="label">Selecciona el valor de X</label>
                </div>
                <Select
                  showSearch={true}
                  placeholder="Seleccionar"
                  style={{ width: "100%" }}
                  value={valorX}
                  onChange={(value) => setXValue(value)}
                >
                  {res_values.map((res, index) => (
                    <Select.Option key={index} value={res.value}>
                      {res.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {/* Select product for the correlation */}
              <div className="col-12 col-md-4 mb-3">
                <div className="row">
                  <label className="label">Productos de correlación</label>
                </div>
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder="Seleccionar"
                  value={principalProducts}
                  style={{ width: "100%" }}
                  onChange={handlePrincipalProducts}
                >
                  {products.map((product, index) => (
                    <Select.Option key={index} value={product.label}>
                      {product.label}
                    </Select.Option>
                  ))}
                </Select>
                <h6 style={{ color: "red" }}>
                  *Ingresa únicamente hasta 10 datos
                </h6>
              </div>

              {/* Operation time */}
              <div className="col-12 col-md-4 mb-3">
                <div className="row">
                  <label className="label">Horario de Operación</label>
                </div>
                <Input
                  value={operation_time_hours + " horas"}
                  style={{ width: "100%", textAlign: "center" }}
                  disabled={true}
                ></Input>
              </div>
            </div>
          </Container>

          {/* Second ROW Execute Correlation NxN Buttons */}
          <div className="mt-2 row text-center">
            <div className="text-center mb-3 col-12">
              <button
                onClick={getCorrelationProducts}
                disabled={!valorX || principalProducts.length < 2}
                className="btn btn-lg"
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                }}
              >
                Calcular Correlación
              </button>
            </div>
          </div>

          {/* Second Container */}
          <Container fluid>
            {/* Print Button and Term Input */}
            <div className="row mx-3">
              {/* Term */}
              <div className="col-12 col-md-3 text-start mb-3">
                <div className="text-center">
                  <label className="label">Plazo de inversión</label>
                </div>
                <Input
                  className="text-center"
                  type="text"
                  value={correlationValue}
                  disabled={true}
                ></Input>
              </div>

              {/* Download Table Button */}
              <div className="col-12 col-md-9 text-end mb-3">
                <button
                  className="btn form-control w-25 w-md-25 text-center"
                  style={{
                    backgroundColor: "#050227",
                    color: "white",
                  }}
                  onClick={handleDownloadPDF}
                >
                  Imprimir tabla
                </button>
              </div>
            </div>

            {/* Selected Products and Correlation Tables */}
            <div className="mt-3 mb-3 mx-3 row text-center">
              {/* Selected Products Table */}
              <div className="col-12 col-md-3 mb-3">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Productos Seleccionados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {principalProducts.slice(0, 10).map((objeto, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{objeto}</td>
                      </tr>
                    ))}
                  </tbody>
                  {principalProducts.length < 1 ? (
                    <tfoot>
                      <tr>
                        <td colSpan="2">Mostrando 0 Productos</td>
                      </tr>
                    </tfoot>
                  ) : (
                    ""
                  )}
                </table>
              </div>

              {/* Correlation NxN Table */}
              <div className="col-12 col-md-9">
                <table className="table table-bordered table-striped">
                  {correlationNXN.length > 0 ? (
                    <>
                      <thead>
                        <tr>
                          <th>Productos</th>
                          {keys.map((key) => (
                            <th key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {keys.map((rowKey) => (
                          <tr key={rowKey}>
                            <th>{rowKey}</th>
                            {keys.map((colKey) => (
                              <td
                                key={colKey}
                                style={{
                                  backgroundColor: getColorForValue(
                                    correlationNXN.find(
                                      (item) => item[rowKey]
                                    )?.[rowKey]?.[colKey]
                                  ),
                                }}
                              >
                                {correlationNXN
                                  .find((item) => item[rowKey])
                                  ?.[rowKey]?.[colKey]?.toFixed(2) ?? ""}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th>Correlación de productos</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td colSpan="3">
                            Mostrando 0 Correlaciones por el momento
                          </td>
                        </tr>
                      </tfoot>
                    </>
                  )}
                </table>
              </div>
            </div>
          </Container>
        </Card>
      </section>

      {/* Notification GIFs */}
      {showRecuerda && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowRecuerda(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={recuerda_gif} width={"150px"} />
          <p
            style={{
              fontSize: "14px",
              color: "#333",
              margin: 0,
            }}
          >
            {randomPhrase}
          </p>
        </div>
      )}

      {showSabias && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowSabias(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={sabias_gif} width={"150px"} />
          <p
            style={{
              fontSize: "14px",
              color: "#333",
              margin: 0,
            }}
          >
            {randomPhrase}
          </p>
        </div>
      )}
    </>
  );
}
