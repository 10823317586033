import { SET_TRADE_DATA, CLEAN_TRADE_DATA } from "../actions/types";

const initialState = {
  vix: {
    templateinfo: {
      adx: { length: 0 },
      macd: {
        fast: 0,
        slow: 0,
        signal: 0,
      },
      stoch: {
        k: 0,
        smooth_k: 0,
        d: 0,
      },
      bbands: { length: 0, std: 0 },
    },
    userinfo: {
      term: "0h",
      term_days: "0 días",
      term_hours: "0 horas",
      resolution: "0h",
      product: "",
      product_name: "por definir",
      volatility: "",
    },
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRADE_DATA:
      return {
        ...state,
        vix: {
          templateinfo: payload.templateinfo,
          userinfo: payload.userinfo,
        },
      };
    case CLEAN_TRADE_DATA:
      return initialState;
    default:
      return state;
  }
}
